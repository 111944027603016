

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dialog-footer {
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-end;
}
.Batchs {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
